export default function Index() {
  return (
    <>
      <div className="absolute top-0 left-0 z-10 w-screen h-screen opacity-20 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500" />
      <div
        className="relative w-screen h-screen bg-cover bg-center"
        style={{ backgroundImage: 'url(https://source.unsplash.com/1600x900/?boat)' }}
      />
    </>
  );
}
